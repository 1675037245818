import { Badge } from 'reactstrap';
import './index.scss';
import checkmark from '../../../../../assets/checkmark.png?react';

const OnboardingPaymentPlan = ({selected, onSelect, name, description, badges, description2}) => {  return (
    <div className={`plan ${selected ? "selected" : ""}`} onClick={() => onSelect(true)}>
      <p className="name">
        <span>{name}</span>{" "}
        {badges && badges.map((badge, index) =>
          <Badge key={`plan-badge-${index}`} color={badge.color}>{badge.text}</Badge>
        )}
      </p>
      <p className="description">{description}</p>
      <p className="description">{description2}</p>
      <div className="checkmark">
        <img src={checkmark} alt="checkmark" />
      </div>
    </div>
  );
};

export default OnboardingPaymentPlan;