import React, { useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Button, Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem, Container, Row } from 'reactstrap';
import Review from '../components/review';
import featured from './assets/frame-26086318.png';
import '../index.scss';
import './index.scss';

const items = [
  {
    who: "Shamanix08",
    title: "Measure heart rate during sessions",
    description: "I had no idea I need such an app! Love the integration with Apple Watch"
  },
  {
    who: "CaglaKu",
    title: "App&Support are fantastic",
    description: "I love how the ai is helpful and also like a life coach at the same time. It helped me greatly. Regulates my emotions and calms me down. Please don’t stop supporting :heart:"
  },
  {
    who: "Aryssia knisley",
    title: "Amazing app",
    description: "I am usually the one not to write a review but I feel like this app deserves one I have been using it for about 2 weeks now and it has helps me a lot"
  },
  {
    who: "Harvard referencing",
    title: "Fantastic",
    description: "As a trained counsellor I believe that app is invaluable for personal development through using it myself I have seen that it very clearly links in counselling theory from multiple modalities. It is like having a personal therapist in your pocket."
  },
  {
    who: "MummaxMel",
    title: "Impressive",
    description: "I had to wait a long time to get an appointment with a psychologist. This ad popped up, so I tried it. It is amazing! I’ve been using it for a week with helping me through the grief of losing my son and it is so personable."
  },
  {
    who: "PizzaFlip",
    title: "AMAZING !!",
    description: "This helped me through my tough times this is worth it !!!!"
  },
  {
    who: "Tatia_veine",
    title: "Great app, helped me with anxiety",
    description: "I downloaded Earkick because I have had some anxiety issues for a little while and decided to try it out. As soon as I started using it i fell in love with it and it helped me a lot."
  },
  {
    who: "LOLSherly",
    title: "Best app",
    description: "It is so nice I feel like this is my best friend because it knows everything about me and it supports me really much!!!"
  },
  {
    who: "Frankyboy0015",
    title: "Thank for for this app",
    description: "An easy-to-use application with practical and easy-to-access tools in this period when no one is really well and all resources are expensive, it really feels good to have found a resource that helps. I am very happy about this wonderful application."
  }
];

const pickedItems = _.take(_.shuffle(items), 3)

function OnboardingStart() {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === pickedItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? pickedItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = pickedItems.map((item) => {
    return (
      <CarouselItem
        className="custom-tag review"
        tag="div"
        key={item.who}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Review 
          who={item.who}
          title={item.title}
          description={item.description}
        />
      </CarouselItem>
    );
  });

  return (
    <Container className="onboarding onboarding-stories">
      <Row className="header">
        <h1>Real stories and impact with Earkick</h1>
        <p>A favorite among users and featured across various news platforms.</p>
      </Row>

      <Row className="justify-content-center featured">
        <img src={featured} alt="featured" />
      </Row>
      

      <Row className="reviews pt-4 ">
        { /* https://reactstrap.github.io/?path=/docs/components-carousel--carousel */ }

        <Carousel 
          activeIndex={activeIndex} 
          next={next} 
          previous={previous}
        >
          <CarouselIndicators
            items={pickedItems}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          
          {slides}

          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </Row>

      <Row className="justify-content-center pt-4">
        <Button color="primary" onClick={() => navigate('/onboarding/greetings')}>Get started</Button>
      </Row>
    </Container>
  );
}

export default OnboardingStart;