import { functions, httpsCallable } from './firebase'
import { create, getOne, subscribeOne, update } from "./db"

const collectionName = 'members'

const memberCreate = (id, data) => { 
  return create(collectionName, id, {
    ...data,
    createdAt: new Date(),
    updatedAt: new Date()
  })
}

const memberGet = (id) => {
  return getOne(collectionName, id)
}

const memberSubscribeById = (id, mutation) => {
  return subscribeOne(collectionName, id, mutation)
}

const memberUpdate = (id, data) => {
  return update(collectionName, id, {
    ...data,
    updatedAt: new Date()
  })
}

const memberDelete = async (appId) => {
  const call = httpsCallable(functions, 'chatMemberDelete')
  const { data } = await call({ appId })
  return data
}

export { 
  memberCreate,
  memberDelete,
  memberGet,
  memberSubscribeById,
  memberUpdate
}