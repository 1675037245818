import React, { lazy, useEffect, useState } from 'react';
import { Button, Container, Row } from 'reactstrap';
import cheering from './assets/cheering.png?react';
import '../index.scss';
import './index.scss';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { webappStripeCheckoutSuccess } from '../../../api/onboarding/stripe';
import config from '../../../config/index'
import { getMixpanel } from '../../../api/mixpanel';
import ScrollToTop from '../../../Components/scroll-ro-top';
import { webappOnboardingSave } from '../../../api/onboarding';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));

const { environment, domain, adjustCode } = config

const getLink = (appId) => {
  const fallback = encodeURIComponent(`http://${domain}/web-onboarding?adj_t=${adjustCode}&onboardingId=${appId}`);
  const adjustDynamicLinkBase = environment === 'production' ? 
    `https://earkick.go.link/web-onboarding?adj_t=${adjustCode}&adj_fallback=${fallback}`: 
    `https://earkickdev.go.link/web-onboarding?adj_t=${adjustCode}&adj_fallback=${fallback}`;
  console.log(`Adjust link: ${adjustDynamicLinkBase}`)
  return adjustDynamicLinkBase
}

function OnboardingFinish({ success }) {
  const { appId } = useSelector(state => state.user)
  const [searchParams] = useSearchParams();
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);
  const [link, setLink] = useState(null)

  const session_id = searchParams.get('session_id')
  console.log({success})
  useEffect(() => {
    if(session_id) {
      webappStripeCheckoutSuccess(appId, session_id).then(console.log)
    }
  }, [session_id])

  useEffect(() => {
    if(appId) {
      const key = 'onboarding.appId'
      const linkBase = [
        getLink(appId),
        `adj_redirect_macos=https%3A%2F%2F${domain}%2Fweb-onboarding`,
        `onboardingId=${appId || localStorage.getItem(key)}`
      ]
      setLink(linkBase.join('&'))
      try {
        webappOnboardingSave(appId, { adjustLink: linkBase.join('&') })
      } catch (error) {
        console.error(error)
      }
    }
  }, [appId])

  const goToDownload = () => {
    const mixpanel = getMixpanel()
    // remove from local storage
    const track = {
      event: "Z00 Automatic redirect to App download page",
      data: {
        platform: "iOS"
      }
    }

    mixpanel.track(track.event, track.data)
    window.location = link
  }

  return (
    <Container className="onboarding onboarding-finish onboarding-standard-section">
      <Row className="cheering">
        <img src={cheering} alt="cheering" />
      </Row>
      
      <Row className="onboarding-content subheader">
        <h2>You&apos;re all set, <br /><br /> {localStorage.getItem('onboarding.name') || '...'}</h2>
      </Row>

      <Row className="cta-button justify-content-center">
        <Button color="primary" disabled={!link} onClick={() => goToDownload()}>Okay, let&apos; go!</Button>
      </Row>

      {triggerMixpanel &&
        <Mixpanel
          success={() => setTriggerMixpanel(false)}
          track={{ 
          event: success ? 'O28 Onboarding Payment success' : 'O29 Onboarding Payment finish', 
          data: {
            appId,
            blog_post_id: localStorage.getItem('blog_post_id')
          }}}
        />
      }
      <ScrollToTop />
    </Container>
  );
}

export default OnboardingFinish;