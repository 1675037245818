import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Privacy from './privacy'
import Terms from './terms'

const OnboardingStartModal = ({ isOpen, toggle, terms, privacy }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalBody>
        {terms && <Terms />}
        {privacy && <Privacy />}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OnboardingStartModal;