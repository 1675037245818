import { Col, Progress, Row } from "reactstrap";
import { ReactComponent as ArrowBack } from "./assets/arrow-back.svg";
import './index.scss';

export default function OnboardingProgressBar({ value, back, next }) {
  return (<Row className="onboarding-progress-bar mb-5 mx-2">
    <Col className="col-2 back" onClick={() => back()}>
      <ArrowBack />
    </Col>
    <Col className="col-8">
      <Progress value={value} />
    </Col>
    {next &&
      <Col className="col-2 next" onClick={() => next()}>
        Skip
      </Col>
    }
    {!next &&
      <Col className="col-2">
      </Col>}
  </Row>)
}