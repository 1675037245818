const plans = [
  {
    id: "price_1R7HYUKinMuQ6g6s8sgXU6H5",
    name: "Annual",
    description: "$89.99 ( $1.73 / week )",
    description2: "after 7 days trial",
    badges: [{ text: 'Save 30%', color: 'success' }],
    trialDays: 7
  },
  {
    id: "price_1R7HXfKinMuQ6g6stSpz4nsW",
    name: "Monthly",
    description: "$14.99 ( $3.74 / week )",
    description2: "after 3 days trial",
    badges: [],
    trialDays: 3
  },

  {
    id: "price_1R7HWmKinMuQ6g6sSgEI8Afh",
    name: "Annual",
    description: "$89.99 ( $1.73 / week )",
    description2: "after 7 days trial",
    badges: [{ text: 'Save 30%', color: 'success' }, { text: 'test', color: 'warning' }],
    trialDays: 7
  },
  {
    id: "price_1R7HUsKinMuQ6g6sjOdr6fwK",
    name: "Monthly",
    description: "$14.99 ( $3.74 / week )",
    description2: "after 3 days trial",
    badges: [{ text: 'test', color: 'warning' }],
    trialDays: 3
  },

]

export default {
  plans
}