import './gdpr.scss'

const Gdpr = () => {
  return (
    <div class="gdpr">
      
      <h3>GDPR Addendum<br />
Effective as of: November [●], 2022</h3>

<p>If you are a resident of the European Union (“EU”), United Kingdom, Lichtenstein, Norway, or Iceland, you may have additional rights under the EU General Data Protection Regulation (the “GDPR”) and the UK-GDPR with respect to your Personal Data, as outlined in this GDPR Addendum (the “Addendum”). 
For this Addendum, we use the terms “Personal Data” and “processing” as they are defined in the GDPR, but “Personal Data” generally means information that can be used to identify a person, and “processing” generally refers to actions that can be performed on data such as its collection, use, storage or disclosure. 
Earkick will be the controller of your Personal Data processed in connection with the Services. Where applicable, this Addendum is intended to supplement, and not replace, our Privacy Policy. If there are any conflicts between the Addendum and the other parts of the Privacy Policy, the policy or portion that is more protective of Personal Data shall control to the extent of such conflict.</p>

<ol>
  <li>
    <span class="term">Our Contact Information</span>
    <p>Earkick is located at 315 Montgomery St., San Francisco, CA - 94104. Please use this address or, preferably, reach out to us at <a href="mailto:hello@earkick.com">hello@earkick.com</a> for any questions, complaints, or requests regarding this GDPR Notice; please include the subject line “GDPR Request.”</p>
    <p>
      Our representative in the European Union is:<br />
      Earkick GmbH<br />
      Meierhofstrasse 23<br />
      Wädenswil
    </p>
  </li>
  <li>
    <span class="term">Types of Personal Data we Collect</span>
    <p>We currently collect and otherwise process the kinds of Personal Data listed above in Section I of the Privacy Policy.</p>
  </li>
  <li>
    <span class="term">How we get the Personal Data and why we have it</span>
    <p>We receive the Personal Data in the ways and for the purposes listed above in Sections I and II of the Privacy Policy. Under the GDPR, the lawful bases we rely on for processing this information are listed in the table below:</p>  

    <ul>
      <li>
        <span class="term">Direct Marketing:</span> Sending email marketing to our Users based on their consent. 
      </li>

      <li>
        <span class="term">Provision of App:</span> We will process all Personal Data as necessary for the performance of contracts to which our Users are a party, such as our Terms of Use and Privacy Policy, in order to provide our App. 
      </li>

      <li>
        <span class="term">Audience Measurement:</span> Pursuant to their consent, our use of analytics on our App to understand how our Users interact with the App. 
      </li>

      <li>
        <span class="term">General Business Development:</span> Our legitimate interest in furthering relationships with our Users, ensuring customer satisfaction, and answering inquiries.
      </li>

      <li>
        <span class="term">Information Security:</span> Our web servers will log your IP address and other information (e.g., browser information, operating system, request date/time, user agent string, referral and exiting URL) in order to maintain an audit log of activities performed. We use this information pursuant to our legitimate interests in tracking App usage, combating DDOS or other attacks, and removing or defending against malicious visitors to the App.
      </li>

      <li>
        <span class="term">Compliance with Applicable Law:</span> We will process Personal Data as needed to fulfill our legal obligations, such as our obligations relating to tax, accounting, and finance.
      </li>
    </ul>
</li>

<br />

<li>
  <span class="term">Recipients of your Personal Data
  </span>
  <p>Earkick personnel shall receive and process your Personal Data for the purposes described above. 
Personal Data is also disclosed to the following categories of US-based recipients to effectuate the purposes described above: hosting providers, analytics providers, customer support services, and marketing and transactional email services.
  </p>
</li>


<li>
  <span class="term">How we store and protect your Personal Data
  </span>
  <p>
We use reasonable administrative, technical, and physical safeguards to protect your Personal Data from loss, misuse, and unauthorized access, disclosure, alteration, or destruction, for which we take into account the nature of the Personal Data, its processing, and the threats posed to it. Unfortunately, no data transmission or storage system can be guaranteed to be secure at all times. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us via email at <a href="mailto:hello@earkick.com">hello@earkick.com</a>.
  </p>
  <p>
We retain your Personal Data for as long as needed to fulfill the purposes for which we obtained it, and delete it if you request it or such data is no longer relevant. In some cases, we may have to retain data to comply with our legal obligations (e.g., accounting, finance, and tax obligations). We will only keep your Personal Data for as long as allowed or required by law.
  </p>
</li>

<li>
  <span class="term">
    Your data protection rights
  </span>
  <p>
    Under data protection law, you have rights, which include: 
  </p>


  <ul>
    <li>  
      Right of access: You have the right to ask us for copies of your Personal Data. 
    </li>
    <li>  
      Right to rectification: You have the right to ask us to rectify Personal Data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. 
    </li>
    <li>  
      Right to erasure: You have the right to ask us to erase your Personal Data in certain circumstances. 
    </li>
    <li>  
      Right to restriction of processing: You have the right to ask us to restrict the processing of your Personal Data in certain circumstances. 
    </li>
    <li>  
      Right to object to processing: You have the the right to object to the processing of your Personal Data in certain circumstances.
    </li>
    <li>  
      Right to data portability: You have the right to ask that we transfer the Personal Data you gave us to another organisation, or to you, in certain circumstances.
    </li>
    <li>  
      Right to withdraw consent: You have the right to withdraw your consent where consent is used as the legal basis for processing your Personal Data.
    </li>
    <li>
      Objecting to Legitimate Interest/Direct Marketing: You may object to Personal Data processed pursuant to our legitimate interest. In such case, we will no longer process your Personal Data unless we can demonstrate appropriate, overriding legitimate grounds for the processing or if needed for the establishment, exercise, or defense of legal claims. You may also object at any time to processing of your Personal Data for direct marketing purposes by clicking “Unsubscribe” within an automated marketing email or by submitting your request to <a href="mailto:hello@earkick.com">hello@earkick.com</a> with the subject line “GDPR Request.” In such case, your Personal Data will no longer be used for that purpose.
    </li>
  </ul>
</li>

<p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. Please contact us at <a href="mailto:hello@earkick.com">hello@earkick.com</a> with the subject line “GDPR Request” if you wish to make a request.</p>
<li>
  <span class="term">
    Transfer of Personal Data to the United States
  </span>
  <p>To transfer your Personal Data to the United States, we rely on the following, as appropriate: (i) appropriate Standard Contractual Clauses to ensure adequate protection for your personal data, (ii) your consent; (iii) the derogations under Article 49(1)(b) and (1)(c) for performance of our contracts with you or to execute a contract in your interest with another entity/person, or (iv) any other valid transfer mechanisms, appropriate safeguards or exceptions under applicable law in effect at the time of such transfer.</p>
</li>

<li>
  <span class="term">How to complain
  </span>
<p>If you have any concerns about our use of your Personal Data, you can make a complaint to us at <a href="mailto:hello@earkick.com">hello@earkick.com</a> with the subject line “GDPR Request.”</p>
</li>

<p>You also have the right to lodge a complaint about the processing of your personal data with a supervisory authority of the European state where you work or live or where any alleged infringement of data protection laws occurred. A list of most of the supervisory authorities can be found here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>
<li>
  <span class="term">Disclosure to public authorities
  </span>
<p>Earkick may be required to disclose Personal Data in response to lawful requests by public authorities, including for the purpose of meeting national security or law enforcement requirements. We may also disclose Personal Data to other third parties when compelled to do so by government authorities or required by law or regulation including, but not limited to, in response to court orders and subpoenas.</p>
</li>

<li>
  <span class="term">Corporate restructuring
  </span>
<p>In the event of a merger, reorganization, dissolution, or similar corporate event, or the sale of all or substantially all of our assets, the information that we have collected, including Personal Data, may be transferred to the surviving or acquiring entity. All such transfers shall be subject to our commitments with respect to the privacy and confidentiality of such Personal Data as set forth in this Addendum. </p>
</li>

<li>
  <span class="term">Updates to this Addendum
  </span>
  <p>If, in the future, we intend to process your Personal Data for a purpose other than that which it was collected, we will provide you with information on that purpose and any other relevant information at a reasonable time prior to such processing. After such time, the relevant information relating to such processing activity will be revised or added appropriately within this Addendum, and the “Effective Date” at the top of this page will be updated accordingly.</p>
</li>
</ol>


    </div>
  )
}

export default Gdpr