import _ from 'lodash'
import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Spinner } from 'reactstrap';

import closeIcon from '../../../../../assets/close-button.png';
import Plan from '../../components/plan';
import heroImage from './assets/third-hero.png?react';
import companiesImage from './assets/companies.png?react';

import './index.scss'
import { useAppSelector } from '../../../../../store/Hooks';

const Mixpanel = lazy(() => import("../../../../../api/mixpanel.jsx"));

const PaymentThird = ({ 
  plans, setPlanSelected, setStripeSession, planSelected,
  startStripe,
  stripeSession,
}) => {
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);
  const navigate = useNavigate()


  return (
    <div className="onboarding onboarding-payment payment variant2 third-page">
      <div className="close-icon">
        <img src={closeIcon} alt="close" onClick={() => navigate("/onboarding/finish")} />
      </div>

      <Row className="justify-content-center hero">
        <img src={heroImage} alt="featured" />
      </Row>

      <div className="clouds"></div>

      <Row className="header">
        <h1>Get started with a 7 day free premium trial!</h1>
      </Row>

      <Row className="stripe-plans">
        <div className="stripe-plans-inner">
          <p className="title">Pick a plan</p>
          {plans.map((plan) => (
            <Plan
            key={plan.id}
            selected={planSelected === plan.id} 
            onSelect={() => {
              setPlanSelected(plan.id)
              setStripeSession(null)
            }} 
            name={plan.name}
            description={plan.description}
            description2={plan.description2}
            badges={plan.badges} 
            />
          ))}
        </div>
      </Row>

      <div className="cta-section">
        <div class="cta-section-inner">
          <p>Cancel anytime in the App Store</p>
          <Button 
            className="purchase-button" 
            onClick={() => startStripe()}
            disabled={!stripeSession}
          >
            {stripeSession ? <>Start my Free Week</> : <Spinner />}
          </Button>
        </div>
      </div>

      <div className="additional-info">
        <div className="additional-info-inner">
          <img src={companiesImage} alt="companies" className="companies-image" />
          <p className="reviews">⭐️⭐️⭐️⭐️⭐️.  2,000+ Reviews</p>
          <p className="join">Join 100k+ people using Earkick</p>
          <p className="disclaimer">Your monthly or annual subscription automatically renews for the same term unless cancelled at least 24 hours prior to the end of the current term. Cancel any time in the App Store at no additional cost; your subscription will then cease at the end of the current term.</p>
        </div>
      </div>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O20 Onboarding Payment', 
          data: {
            appId,
            variant: 1,
            page: 3
          }}}
        />
      }
    </div>

  );
};

export default PaymentThird;