import defaultPanda from './assets/panda.png?react';
import './index.scss';

const Plan = ({ question, description, panda }) => {  
  return (
    <div className="question">
      <div className="panda">
        <img src={panda || defaultPanda} alt="checkmark" />
      </div>
      <div className="text">
        <div className="question">{question}</div>
        {description && <p className="description">{description}</p>}
      </div>
    </div>
  );
};

export default Plan;