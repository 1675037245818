import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import Question from '../components/question';
import achievements from '../../../assets/onboarding/achievements.png';
import '../index.scss';
import './index.scss';
import OnboardingProgressBar from '../components/progress-bar';
import ScrollToTop from '../../scroll-ro-top';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));

function OnboardingAchievements() {
  const navigate = useNavigate()
  const { appId } = useSelector(state => state.user)
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  return (
    <Container className="onboarding onboarding-achievements onboarding-standard-section">
      <OnboardingProgressBar
        value={55}
        back={() => navigate("/onboarding/goals")}
      />

      <div className="onboarding-content">
        <Question 
          question="Here&apos;s what you can achieve with Earkick!" 
        />
        
        <Row className="achievements-container justify-content-center pt-4">
          <img src={achievements} alt="Achievements" />
        </Row>
      </div>

      <Row className="pt-4 cta-button justify-content-center pt-2">
        <Button color="primary" onClick={() => navigate("/onboarding/security")}>Continue</Button>
        { /* 
        <Button color="primary" onClick={() => navigate("/onboarding/payment")}>Continue</Button>
        */ }
      </Row>

      {triggerMixpanel &&
        <Mixpanel
          success={() => setTriggerMixpanel(false)}
          track={{ 
          event: 'O09 Onboarding Goals 2 achievements', 
          data: {
            appId
          }}}
        />
      }
      <ScrollToTop />
    </Container>
  );
}

export default OnboardingAchievements;