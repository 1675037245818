import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'onboarding',
  initialState: {
    attribution: null,
    age: null,
    goals: [],
    name: ""
  },
  reducers: {
    setAge(state, action) {
      state.age = action.payload
    },
    setAttribution(state, action) {
      state.attribution = action.payload
    },
    setGoals(state, action) {
      state.goals = action.payload
    },
    setName(state, action) {
      state.name = action.payload
    }
  }
})

export const { 
  setAge,
  setAttribution,
  setGoals,
  setName
} = slice.actions

export default slice.reducer