import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAttribution } from '../store';
import panda from '../../../assets/pandas/with-pencil.svg'
import Question from '../components/question';
import Questions from '../components/questions';
import '../index.scss';
import './index.scss';
import OnboardingProgressBar from '../components/progress-bar';
import { useAppSelector } from '../../../store/Hooks';
import { memberUpdate } from '../../../api/member';
import ScrollToTop from '../../../Components/scroll-ro-top';
import { webappOnboardingSave } from '../../../api/onboarding';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));

const questions = [
  { name: "News / article / blog"},
  { name: "YouTube"},
  { name: "Friends / family"},
  { name: "Facebook / Instagram"},
  { name: "TV"},
  { name: "TikTok"},
  { name: "Google Search"},
]

function OnboardingAttribution() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onboarding = useSelector(state => state.onboarding)
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  const setSelected = (value) => {
    dispatch(setAttribution(value))
    memberUpdate(appId, { onboarding: { ...onboarding, attribution: value } })
    webappOnboardingSave(appId, { attribution: value })
    setTimeout(() => navigate("/onboarding/goals"), 300)
  }

  return (
    <Container className="onboarding onboarding-question onboarding-standard-section onboarding-attribution">
      <OnboardingProgressBar
        value={45}
        back={() => navigate("/onboarding/age")}
      />

      <div className="onboarding-content">
        <Question
          panda={panda}
          question="Got it! How did you hear about Earkick?" 
        />
        
        <Row className="justify-content-center pt-4">
          <Questions 
            questions={questions.map((question) => ({ ...question, id: question.name }))}
            onSelect={setSelected}
            selected={[onboarding.attribution]}
          />
        </Row>
      </div>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O08 Onboarding Attribution', 
          data: {
            appId
          }}}
        />
      }

      <ScrollToTop />
    </Container>
  );
}

export default OnboardingAttribution;