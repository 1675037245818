import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

const webappStripeCheckoutCreateSession = async (appId, product, trialDays, success_url, cancel_url) => {
  const call = httpsCallable(functions, 'webappStripeCheckoutCreateSession')

  const { data } = await call({
    appId,
    product,
    trialDays,
    success_url, 
    cancel_url
  })

  return data
}

const webappStripeCheckoutSuccess = async (appId, session_id) => {
  const call = httpsCallable(functions, 'webappStripeCheckoutSuccess')

  const { data } = await call({
    appId, 
    session_id
  })

  return data
}

export {
  webappStripeCheckoutCreateSession,
  webappStripeCheckoutSuccess
}
