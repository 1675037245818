import { configureStore } from "@reduxjs/toolkit";
import LayoutSlice from "./Reducers/Layout";
import authReducer from "./Reducers/AuthReducer";
import MainSidebarSlice from "./Reducers/Messenger/MainSidebar";
import ChatReducer from "./Reducers/Messenger/Chat";
import MessengerSlice from "./Reducers/Messenger/Messenger";
import TemplateCustomizerReducers from "./Reducers/TemplateCustomizerReducers";
import onboarding from '../Components/onboarding/store'
import user from "./Reducers/user"
import personas from "./Reducers/personas"

export const store = configureStore({
  reducer: {
    layout: LayoutSlice,
    auth: authReducer,
    mainSidebar: MainSidebarSlice,
    chat: ChatReducer,
    messenger: MessengerSlice,
    onboarding: onboarding,
    templateCustomizer: TemplateCustomizerReducers,
    user,
    personas
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
