import _ from 'lodash'
import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';


import bellImage from './assets/bell.png?react';

import './index.scss'
import { useAppSelector } from '../../../../../store/Hooks';
import closeIcon from '../../../../../assets/close-button.png';

const Mixpanel = lazy(() => import("../../../../../api/mixpanel.jsx"));


const PaymentSecond = ({ 
  plans, setPlanSelected, setStripeSession, planSelected,
  startStripe,
  stripeSession,
}) => {
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);
  const navigate = useNavigate();

  return (
    <Container className="onboarding onboarding-payment payment variant2 second-page">
      <div className="close-icon">
        <img src={closeIcon} alt="close" onClick={() => navigate("/onboarding/finish")} />
      </div>        

      <Row className="header">
        <h1>We&apos;ll remind you 2 days before your trial ends</h1>
      </Row>

      <Row className="justify-content-center bell">
        <img src={bellImage} alt="bell" />
      </Row>

      <Row className="justify-content-center cancel-info">
        <p>Easy to cancel, no penalties or fees</p>
      </Row>

      <div className="cta-section">
        <div class="cta-section-inner">
          <Button
            className="purchase-button" 
            onClick={() => navigate("/onboarding/payment/third")}
            >Start My Free Week</Button>
        </div>
      </div>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O20 Onboarding Payment',
          data: {
            appId,
            variant: 2,
            page: 2
          }}}
        />
      }
    </Container>

  );
};

export default PaymentSecond;