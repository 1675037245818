import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions, httpsCallable } from 'firebase/functions'
import ReactObserver from 'react-event-observer';
import config from '../config/firebase.json'

console.log('process.env', process.env)
const { storageBucket } = config

const app = initializeApp(config)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage()
const functions = getFunctions()

const firebaseObserver = ReactObserver();

onAuthStateChanged( auth, change => {
  firebaseObserver.publish("authStateChanged", change)
});

export {
  app,
  auth,
  db,
  firebaseObserver,
  functions,
  httpsCallable,
  storage,
  storageBucket
}
