import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import phone from './assets/phone.png';
import addARoutine from './assets/add-a-routine.png';
import cards from './assets/cards.png';
import close from '../../../assets/close-button.png?react';

import '../index.scss';
import '../showcase.scss';
import './index.scss';

function ShowcaseHabits() {
  const navigate = useNavigate()

  return (
    <Container className="onboarding app-showcase">
      <div className="close-button" onClick={() => navigate("/onboarding/payment")}>
        <img src={close} alt="close" />
      </div>

      <Row className="justify-content-center pt-5 phone phone-habits">
        <img src={phone} alt="phone" className="main" />
        <img src={addARoutine} alt="add a routine" className="add-a-routine" />
        <img src={cards} alt="cards" className="cards" />
      </Row>
            
      <Row className="pt-5 description">
        <p>Track your progress and <strong>build healthy habits</strong> for lasting growth.</p>
      </Row>
            
      <Row className="cta-button justify-content-center pt-4">
        <Button color="primary" onClick={() => navigate("/onboarding/showcase-peace")}>Next</Button>
      </Row>
    </Container>
  );
}

export default ShowcaseHabits;