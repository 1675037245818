import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAge } from '../store';
import panda from '../../../assets/pandas/with-pencil.svg'
import Question from '../components/question';
import Questions from '../components/questions';
import '../index.scss';
import './index.scss';
import OnboardingProgressBar from '../components/progress-bar';
import { memberUpdate } from '../../../api/member';
import ScrollToTop from '../../scroll-ro-top';
import { webappOnboardingSave } from '../../../api/onboarding';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));

const questions = [
  { name: "Under 18"},
  { name: "18-24"},
  { name: "25-34"},
  { name: "35-44"},
  { name: "45-54"},
  { name: "55-64"},
  { name: "65+"},
]

function OnboardingAge() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onboarding = useSelector(state => state.onboarding)
  const { appId } = useSelector((state) => state.user)
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);
  const [showContinue, setShowContinue] = useState(false);
  const setSelected = (value) => {
    dispatch(setAge(value))
    memberUpdate(appId, { onboarding: { ...onboarding, age: value } })
    webappOnboardingSave(appId, { age: value })

    setTimeout(() => {
      navigate("/onboarding/attribution")
    }, 300)

    setTimeout(() => {
      setShowContinue(true)
    }, 1000)
  }

  return (
    <Container className="onboarding onboarding-age onboarding-standard-section">
      <OnboardingProgressBar
        value={40}
        back={() => navigate("/onboarding/just-few-questions")}
      />

      <div className="onboarding-content">
        <Question
          panda={panda}
          question="How old are you?" 
          description="Specify your age to tailor your experience better for you."
        />
        
        <div className="justify-content-center pt-4">
          <Questions 
            questions={questions.map((question) => ({ ...question, id: question.name }))}
            onSelect={setSelected}
            selected={[onboarding.age]}
          />
        </div>
      </div>

      {showContinue &&
      <Row className="cta-button justify-content-center pt-5">
        <Button color="primary" onClick={() => navigate("/onboarding/goals")}>Continue</Button>
      </Row>
      }

      {triggerMixpanel &&
        <Mixpanel
          success={() => setTriggerMixpanel(false)}
          track={{ 
          event: 'O07 Onboarding Age', 
          data: {
            appId
          }}}
        />
      }
      <ScrollToTop />
    </Container>
  );
}

export default OnboardingAge;