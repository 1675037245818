import config from '../../../config/index'

const { environment } = config

const stripePlans = environment !== "DEVELOPMENT" ? 
  [
    //"price_1QRyOKKinMuQ6g6s95o1ZvQY",
    //"price_1QRyOkKinMuQ6g6sFdcyY4xD",
    "price_1R7HYUKinMuQ6g6s8sgXU6H5",
    "price_1R7HXfKinMuQ6g6stSpz4nsW",
  ]
: [
  //"price_1QMsBPKinMuQ6g6spckrAF4S",
  //"price_1QMsBlKinMuQ6g6sTsLojLg5",
  "price_1R7HWmKinMuQ6g6sSgEI8Afh",
  "price_1R7HUsKinMuQ6g6sjOdr6fwK",
]

export {
  stripePlans
}