import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import phone from './assets/phone.png';
import close from '../../../assets/close-button.png?react';

import '../index.scss';
import '../showcase.scss';

function ShowcasePersonalize() {
  const navigate = useNavigate()

  return (
    <Container className="onboarding app-showcase">
      <div className="close-button" onClick={() => navigate("/onboarding/payment")}>
        <img src={close} alt="close" />
      </div>

      <Row className="justify-content-center pt-5 phone">
        <img src={phone} alt="phone" />
      </Row>
            
      <Row className="pt-5 description">
        <p>Chat with <strong>Panda</strong> - ready to support you anytime.</p>
      </Row>
            
      <Row className="cta-button justify-content-center pt-4">
        <Button color="primary" onClick={() => navigate("/onboarding/showcase-habits")}>Next</Button>
      </Row>
    </Container>
  );
}

export default ShowcasePersonalize;