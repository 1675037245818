import { useState } from 'react';
import _ from 'lodash';
import './index.scss';
import checkmark from '../../../../assets/checkmark.png?react';


const Questions = ({ questions, onSelect, selected: defaultSelected, multiple }) => {
  const [selected, setSelected] = useState(defaultSelected);
  
  return (
    <div className="questions">
      {questions.map((question, index) => (
        <div 
          key={index}
          className={`question ${_.indexOf(selected, question.id) !== -1 ? 'selected' : ''}`}
          onClick={() => {
            const selection = _.indexOf(selected, question.id) === -1

            if(multiple) {
              if(selection) {
                setSelected([...selected, question.id])
                onSelect([...selected, question.id])
              } else {
                setSelected(_.without(selected, question.id))
                onSelect(_.without(selected, question.id))
              }
            } else {
              setSelected([question.id])
              onSelect(question.id)
            }
          }}
        >
          {question.icon && 
            <span className="icon">{question.icon} </span>
          }
          <span>
            {question.name}
          </span>
          <div className="checkmark">
            <img src={checkmark} alt="checkmark" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Questions;