import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import panda from '../../../assets/pandas/greeting.png'
import OnboardingProgressBar from '../components/progress-bar';
import GreetPanda from '../components/greet-panda';
import '../index.scss';
import { useSelector } from 'react-redux';
import ScrollToTop from '../../scroll-ro-top';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));

function OnboardingJustFewQuestions() {
  const navigate = useNavigate()
  const { appId } = useSelector(state => state.user)
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  return (
    <Container className="onboarding onboarding-standard-section">
      <OnboardingProgressBar 
        value={20}
        back={() => navigate("/onboarding/meet")}
      />

      <Row className="onboarding-content">
        <GreetPanda
          text={<>Just <strong>3 quick questions</strong> before your first check-in with me.</>}
          panda={<img src={panda} alt="Panda" />}
          shadow={true}
        />
      </Row>

      <Row className="cta-button justify-content-center pt-5">
        <Button color="primary" onClick={() => navigate("/onboarding/age")}>Continue</Button>
      </Row>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O06 Onboarding Just 4 questions', 
          data: {
            appId
          }}}
        />
      }

      <ScrollToTop />
    </Container>
  );
}

export default OnboardingJustFewQuestions;