import { useEffect } from 'react';
import securityHeroImage from './security/assets/hero.png'
import achievementsImage from '../../assets/onboarding/achievements.png';
import pandaMeet from '../../assets/pandas/panda-want-a-hug.svg'
import cheering from './finish/assets/cheering.png?react';

import heroImage from './payment/variants/2/assets/hero.png?react';
import plansImage from './payment/variants/2/assets/plans.png?react';
import bellImage from './payment/variants/2/assets/bell.png?react';
import thirdHeroImage from './payment/variants/2/assets/third-hero.png?react';

const preloads = [
  securityHeroImage,
  achievementsImage,
  pandaMeet,
  cheering,
  heroImage,
  plansImage,
  bellImage,
  thirdHeroImage
]

export default function PreloadImages() {
  useEffect(() => {
    preloads.forEach(image => {
      const img = new Image()
      img.src = image
    })
  }, [])

  return null
}