import { lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Container, Row } from 'reactstrap';
import panda from '../../../assets/pandas/panda-want-a-hug.svg'
import OnboardingProgressBar from '../components/progress-bar';
import GreetPanda from '../components/greet-panda';
import '../index.scss';
import ScrollToTop from '../../../Components/scroll-ro-top';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));
const PreloadImages = lazy(() => import('../preload-images'));

function OnboardingMeet() {
  const navigate = useNavigate()
  const name = useSelector(state => state.onboarding.name)
  const { appId } = useSelector(state => state.user)
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  return (
    <Container className="onboarding onboarding-name onboarding-standard-section">
      <ScrollToTop />
      <OnboardingProgressBar 
        value={20}
        back={() => navigate("/onboarding/name")}
      />

      <Row className="onboarding-content">
        <GreetPanda
          text={`Nice to meet you ${name}!`}
          panda={<img src={panda} alt="Panda" />}
          shadow={true}
        />
      </Row>

      <Row className="cta-button justify-content-center pt-5">
        <Button color="primary" onClick={() => navigate("/onboarding/just-few-questions")}>Continue</Button>
      </Row>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O05 Onboarding Nice to meet you', 
          data: {
            appId
          }}}
        />
      }    
      <PreloadImages />
    </Container>
  );
}

export default OnboardingMeet;