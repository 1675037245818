import { Route, Routes } from "react-router-dom";
import PaymentSecond from "./second";
import PaymentFirst from "./first";
import PaymentThird from "./third";

export default function Payment({ 
  plans, setPlanSelected, setStripeSession, planSelected,
  startStripe,
  stripeSession,
}) {
  return (
    <Routes>
      <Route path='' element={<PaymentFirst />} />
      <Route path='second' element={<PaymentSecond />} />
      <Route path='third' element={<PaymentThird
        plans={plans}
        setPlanSelected={setPlanSelected}
        setStripeSession={setStripeSession}
        planSelected={planSelected}
        startStripe={startStripe}
        stripeSession={stripeSession}
      />} />
    </Routes>
  )
}