import { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import AuthenticationObserver from "./Components/auth/observer";
import Routers from "./Routers/Routers";
import { useAppSelector } from "./store/Hooks";
import 'react-toastify/dist/ReactToastify.min.css';

const Mixpanel = lazy(() => import("./api/mixpanel.jsx"));

const App = () => {
  const color = useSelector((state) => state.templateCustomizer.colorPicker);
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(false);

  useEffect(() => {
    // initialize color style
    const activeColor = color.find((item) => item.active);
    document.documentElement.className = activeColor?.color;
  }, [color]);

  useEffect(() => {
    if(!appId) {
      
      return () => {}
    }

    setTriggerMixpanel(true);
  }, [appId])

  useEffect(() => {
    // Check for blog_post_id in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const blogPostId = urlParams.get('blog_post_id');
    if (blogPostId) {
      localStorage.setItem('blog_post_id', blogPostId);
    }
  }, []);

  return (
    <AuthenticationObserver>
        <Routers />
        {triggerMixpanel &&
          <Mixpanel
            identify={appId}
            success={() => setTriggerMixpanel(false)}
          />
        }
      <ToastContainer />
    </AuthenticationObserver>
  );
};

export default App;
