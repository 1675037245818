import { Row } from "reactstrap";
import shadowImage from '../../../../assets/pandas/shadow-ellipse.svg';
import './index.scss';

export default function GreetPanda ({ text, panda, shadow }) {
  return (
    <div className="greet-panda">
      <div className="text">
        <h2>{text}</h2>
      </div>
      <div className="panda">
        {shadow &&
          <div className="panda-shadow">
            <img src={shadowImage} alt="shadow" />
          </div>
        }
        <div className="panda-image">
          {panda}
        </div>

      </div>
    </div>
  )
}