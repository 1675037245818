import "./privacy.scss"
import Gdpr from './gdpr'

const PrivacyPolicy = () => (
  <div className="privacy-policy flex-column main-content ">
    <h1>Privacy policy</h1>
    <p>
      We  at  Earkick,  Inc.  (“<span class="term">Earkick</span>,”  “<span class="term">we</span>,”  “<span class="term">us</span>,”  or  “<span class="term">our</span>”)  have  created  this  privacy  policy  (this  “<span class="term">Privacy Policy</span>”) because we know that you care about how information you provide to us is used and shared. This Privacy  Policy  relates  to  the  information  collection  and  use  practices  of  Earkick  in  connection  with  our App.
      </p>
      <p>
      By  clicking  “I  AGREE,”  or  otherwise  manifesting  your  asset  to  the  Privacy  Policy  and  accompanying Terms  of  Use,  when  you  sign  up  to  access  and  use  the  App,  you  acknowledge  that  you  have  read, understood  and  agree  to  be  legally  bound  by  the  terms  of  this  Privacy  Policy  and  the  accompanying Terms of Use. 
      </p>
      <p>
      Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in our Terms of Use.
    </p>

    <div className="privacy-policy-section">
      <h3>The Information We Collect and/or Receive</h3>
      <p>
      In  the  course  of  operating  the  App,  and/or  interacting  with  you,  we  will  collect  (and/or  receive)  the following types of information. You authorize us to collect and/or receive such information.
      </p>
      
      <h4>1.    Contact Information.</h4>
      <p>

      If  you  would  like  to  use  the  App,  you  will  need  to  download  the  App  from  the  Apple,  Android  or  any other app store through which the App is made available to you. If you contact us for support via email or customer  support  contact  form  in  any  other  manner,  you  will  have  to  provide  your  email  address (collectively,  the  “Contact  Information”).  The  Contact  Information  is  used  to  provide  the  requested service or information.
      </p>
      
      <h4>2.    Mental Health Related Information.</h4>
      
      <p>      
      The App allows you to track and monitor your mental health and also keep a mental health journal in a variety of ways, including but not limited to, tracking your mood levels and anxiety and creating video and voice memos for specific situations (collectively, the “Mental Health Related Information”). Your Mental Health Related Information is stored within the App on your device. You may access, review, delete, or make changes to your Mental Health Related Information through the settings on your device. Your Mental Health Related Information is transmitted to Earkick in an anonymous way to be analyzed by our AI models and send back information to your device. No data is permanently stored or sent to any other person or entity. When subscribing to Earkick's premium plan, you have the opportunity to synch your data on your Apple iCloud account. Earkick is in no way responsible for the protection of the Mental Health Related Information stored on your device or on Apple iCloud. You are solely responsible for the protection of such information on your device or if applicable  on Apple iCloud.
      </p>
      
      
      <h4>3.    Other Information.</h4>  
      <p>      
      In addition to the Contact Information, we may collect additional information (the “Other Information”).  Such Other Information may include:
      <ol type="a">
        <li start="a"><span class="term">From Your Activity.</span>  Information that we automatically collect when you use the App, including, without limitation:
          <ul>
            <li>IP addresses, browser type and language, referring and exit pages and URLs, date and time, amount of time spent on particular pages, what sections of the App you visit, etc.;</li>
            <li>
              Information about a mobile device, including universally unique ID (“UUID”), App type and version (e.g., iOS or Android), carrier and country location, hardware and processor information (storage, chip speed, camera resolution, NFC enabled, and network type (WiFi, 2G, 3G, 4G); and
            </li>
            <li>
              Activity and usage information occurring via the App, including tagging data, favorites, preferences, session lengths; and similar data.</li>
          </ul>
        
        </li>
        <li><span class="term">From Cookies.</span>   We collect information using “cookie” technology. Cookies are small packets of data that a website stores on your computer’s or mobile device’s hard drive so that your computer will  “remember”  information  about  your  visit.   We  use  session  cookies,  which  expire  once  you close  your  web  browser,  to  help  us  collect  Other  Information  and  to  enhance  your  experience using the App. If you do not want us to place a cookie on your hard drive, you may be able to turn that  feature  off  on  your  computer  or  mobile  device.  Please  consult  your  Internet  browser’s documentation for information on how to do this and how to delete persistent cookies. However, if you decide not to accept cookies from us, the App may not function properly.
        </li>
        <li>  
        <span class="term">Third-Party  Analytics.</span> We  may  use  one  or  more  third-party  analytics  services  to  evaluate  your use  of  the  App,  compile  reports  on  activity  (based  on  their  collection  of  IP  addresses,  Internet service  provider,  browser  type,  operating  system  and  language,  referring  and  exit  pages  and URLs,  data  and  time,  amount  of  time  spent  on  particular  pages,  what  sections  of  the  App  you visit, number of links clicked while on the App, search terms and other similar usage data), and analyze  performance  metrics.  These  third  parties  use  cookies  and  other  technologies  to  help analyze and provide us the data. By accessing and using the App, you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy. For more information on these third parties, including how to opt out from certain data collection, please visit the sites below. Please be advised that if you opt out of any service, you may not be able to use the full functionality of the App.
        <br />
        <br />
        For Mixpanel, please visit: <a target="_blank" href="https://mixpanel.com/legal/privacy-policy/">https://mixpanel.com/legal/privacy-policy/</a>
        <br />
        For Google Firebase, please visit: <a target="_blank" href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a></li>

      </ol>
      </p>
    </div>

    <div className="privacy-policy-section">
      <h3>How We Use and Share the Information</h3>

      <p>
        You authorize us to use the Contact Information, Mental Health Related Information, and Other Information (collectively, the “<span class="term">Information</span>”) to provide you the App, solicit your feedback, inform you about our products and services and those of our third-party marketing partners, and to improve our App.

      </p>
      <p>
        You also authorize us to use and/or share your Information as described below.
      </p>

      <ul>
        <li>
          <span class="term">Agents, Providers and Related Third Parties.</span>       	
          We may engage other companies and individuals to perform certain business-related functions on our behalf. Examples may include providing technical assistance, order fulfillment, customer service, and marketing assistance.  These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law. We may also share your Information with any of our parent companies, subsidiaries, or other companies under common control with us.
        </li>
        <li><span class="term">Aggregated Information. </span> 
        In an ongoing effort to better understand our users and our App, we might analyze your Information in aggregate form in order to operate, maintain, manage, and improve the App.  This aggregate information does not identify you personally.  We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose aggregated user statistics in order to describe our App to current and prospective business partners and to other third parties for other lawful purposes.
        </li>
        <li><span class="term">Business Transfers.
          </span>  As we develop our businesses, we might sell or buy businesses or assets.  In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, your Information may be part of the transferred assets.
          </li>
        <li><span class="term">Legal Requirements.</span> To the extent permitted by law, we may also disclose your Information: (i) when required by law, court order, or other government or law enforcement authority or regulatory agency; or (ii) whenever we believe that disclosing such information is necessary or advisable, for example, to protect the rights, property, or safety of Earkick or others.
          
          </li>
        <li><span class="term">Group account.
          </span>
          If you have received access to the App through a group account URL or QR Code, we will share your Information in an aggregated anonymous manner with the administrator of the group account in the form of reports.
          </li>
      </ul>





        <h3>Accessing and Modifying Information and Communication Preferences</h3>

      <p>
        If you have provided us any personal information, you may access, remove, review, and/or make changes to the same by contacting us at <a href="mailto:hello@earkick.com">hello@earkick.com</a>. In addition, you may manage your receipt of marketing and non-transactional communications by clicking on the “Unsubscribe” link located on the bottom of any Earkick marketing e-mail.  We will use commercially reasonable efforts to process such requests in a timely manner.  You should be aware, however, that it is not always possible to completely remove or modify information in our subscription databases.  
      </p>

      <h3>How We Protect the Information</h3>
      <p>
        We take commercially reasonable steps to protect your Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction.  Please understand, however, that no security system is impenetrable.  We cannot guarantee the security of our databases or the databases of the third parties with which we may share such information, nor can we guarantee that the information you supply will not be intercepted while being transmitted over the Internet.  In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.  
      </p>

        <h3>External Sites</h3>
        <p>

        The App may, from time to time, contain links to external websites. Earkick encourages you to review the privacy and security policies of any externally linked websites that may be accessed through the App. Earkick assumes no responsibility or liability for the information collection and disclosure practices of any external websites that a user can access through the App.  Please check the privacy policies of these external websites before you submit any personal information to them.
        </p>

        <h3>
          Children’s Information
        </h3>
        <p>

        We do not knowingly collect personal information from children under the age of 18 through the App. If you are under 18, please do not give us any personal information.  We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide personal information through the App without their permission.  If you have reason to believe that a child under the age of 18 has provided personal information to us, please contact us, and we will endeavor to delete that information from our databases.
        </p>
        <h3>
        Important Notice to Non-U.S. Residents
        </h3>

        <p>

        The App and its servers are operated in the United States.  If you are located outside of the United States, please be aware that any information you provide to us maybe transferred to, processed, maintained, and used on computers, servers, and systems located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction.  If you are located outside the United States and choose to use the App, you do so at your own risk.  
        </p>
        <p>
        If you are a resident of the European Union (“<span class="term">EU</span>”), United Kingdom, Lichtenstein, Norway, or Iceland, you may have additional rights under the EU General Data Protection Regulation (the “<span class="term">GDPR</span>”) with respect to your Personal Data, as outlined in our GDPR Addendum (the “<span class="term">Addendum</span>”) at the end of this Privacy Policy. 
        </p>

        <h3>Notice to California Residents</h3>
        <p>Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to obtain certain information about the types of personal information that companies with whom they have an established business relationship (and that are not otherwise exempt) have shared with third parties for direct marketing purposes during the preceding calendar year, including the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. If you wish to submit a request pursuant to Section 1798.83, please contact us via email at <a href="mailto:hello@earkick.com">hello@earkick.com</a>.</p>

        <h3>DO  NOT TRACK</h3>
        <p>Earkick does not monitor, recognize, or honor any opt-out or do not track mechanisms, including general web browser “Do Not Track” settings and/or signals. </p>
        <h3>Notice to Nevada Residents</h3>
        <p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal information to third parties. You can exercise this right by contacting us at <a href="mailto:hello@earkick.com">hello@earkick.com</a> with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account. Please note that we do not currently sell your personal information as sales are defined in Nevada Revised Statutes Chapter 603A.</p>
        <h3>Changes to This Privacy Policy</h3>

        <p>This Privacy Policy is effective as of the date stated at the top of this Privacy Policy.  We may change this Privacy Policy from time to time with or without notice to you. Any such changes will be posted on the App. By accessing and/or using the App after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of your information is governed by the Privacy Policy in effect at the time we collect the Information.  Please refer back to this Privacy Policy on a regular basis. </p>

        <h3>How to Contact Us</h3>

        <p>If you have any questions about this Privacy Policy or to report a privacy issue, please contact us in one of the following ways:</p>

        <p>Email: <a href="mailto:hello@earkick.com">hello@earkick.com</a> </p>

        <p>Or write to us at:</p>

        <p>Earkick, Inc<br />
        315 Montgomery St.<br />
        San Francisco<br />
        CA - 94104<br />
          USA</p>


        <Gdpr></Gdpr>
    </div>
  </div>
)

export default PrivacyPolicy
