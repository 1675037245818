import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import { setGoals } from '../store';
import Question from '../components/question';
import Questions from '../components/questions';
import '../index.scss';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import OnboardingProgressBar from '../components/progress-bar';
import { useAppSelector } from '../../../store/Hooks';
import { memberUpdate } from '../../../api/member';
import ScrollToTop from '../../../Components/scroll-ro-top';
import { webappOnboardingSave } from '../../../api/onboarding';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));

const questions = [
  {
    icon: "🐼", 
    name: "Reduce Stress"
  },
  { 
    icon: "🗣️",
    name: "Talk to someone"
  },
  { 
    icon: "🧠",
    name: "Improve mental health"
  },
  { 
    icon: "❤️",
    name: "Know yourself better"
  },
  { 
    icon: "💪️",
    name: "Build healthy habits"
  },
  { 
    icon: "😌",
    name: "Less anxiety"
  },
]

function OnboardingGoals() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onboarding = useSelector(state => state.onboarding)
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  const setSelected = (value) => {
    dispatch(setGoals(value))
    memberUpdate(appId, { onboarding: { ...onboarding, goals: value } })
    webappOnboardingSave(appId, { goals: value })
  }

  return (
    <Container className="onboarding onboarding-question onboarding-goals onboarding-standard-section">
      <OnboardingProgressBar
        value={50}
        back={() => navigate("/onboarding/attribution")}
      />

      <div className="onboarding-content">
        <Question 
          question="What are your goals?" 
          description="Choose one or more goals for better personalization."
        />
        
        <div className="justify-content-center pt-4">
          <Questions
            multiple={true}
            questions={questions.map((question) => ({ ...question, id: question.name }))}
            onSelect={setSelected}
            selected={onboarding.goals}
          />
        </div>
      </div>

      <Row className="cta-button justify-content-center pt-5">
        <Button color="primary" onClick={() => navigate("/onboarding/achievements")}>Continue</Button>
      </Row>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O08 Onboarding Goals', 
          data: {
            appId
          }}}
        />
      }

      <ScrollToTop />
    </Container>
  );
}

export default OnboardingGoals;