import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import DelayedFallback from "./delayed-fallback"
import OnboardingAchievements from "../Components/onboarding/achievements";
import Start from "../Components/onboarding/start";
import Greetings from "../Components/onboarding/greetings";
import Age from "../Components/onboarding/age";
import Name from "../Components/onboarding/name";
import Meet from "../Components/onboarding/meet";
import OnboardingJustFewQuestions from "../Components/onboarding/just-few-questions";
import Attribution from "../Components/onboarding/attribution";
import Goals from "../Components/onboarding/goals";
import ShowcasePersonalize from "../Components/onboarding/showcase-personalize";
import ShowcaseHabits from "../Components/onboarding/showcase-habits";
import ShowcasePeace from "../Components/onboarding/showcase-peace";
import ShowcaseWellness from "../Components/onboarding/showcase-wellness";
import Stories from "../Components/onboarding/stories";
import OnboardingSecurity from "../Components/onboarding/security";
import Payment from "../Components/onboarding/payment";
import Finish from "../Components/onboarding/finish";

export default function RoutesIndex () {

  return (
    <Suspense className='main-container' fallback={<DelayedFallback />}>
      <Routes>
        <Route path='' element={<Start />} />
        <Route path='start' element={<Start />} />
        <Route path='greetings' element={<Greetings />} />
        <Route path='age' element={<Age />} />
        <Route path='name' element={<Name />} />
        <Route path='meet' element={<Meet />} />
        <Route path='just-few-questions' element={<OnboardingJustFewQuestions />} />
        <Route path='attribution' element={<Attribution />} />
        <Route path='goals' element={<Goals />} />
        <Route path='achievements' element={<OnboardingAchievements />} />
        <Route path='showcase-personalize' element={<ShowcasePersonalize />} />
        <Route path='showcase-habits' element={<ShowcaseHabits />} />
        <Route path='showcase-peace' element={<ShowcasePeace />} />
        <Route path='showcase-wellness' element={<ShowcaseWellness />} />
        <Route path='stories' element={<Stories />} />
        <Route path='security' element={<OnboardingSecurity />} />
        <Route path='payment/*' element={<Payment />} />
        <Route path='finish' element={<Finish />} />
        <Route path='success' element={<Finish success={true} />} />
        <Route path="*" element={<p>404</p>} />
      </Routes>
    </Suspense>
  )
}