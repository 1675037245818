import "./terms.scss"

const TermsOfService = ({canonical}) => (
  <div class="main-content terms-of-service">
      <h1>TERMS OF USE</h1>
    <strong>
<span >Last Updated: </span>
<span >12/9/2022</span>
</strong>
    <p >
<span >
</span>
</p>
<p >
<span >Earkick, Inc. (&ldquo;</span>
<span class="term">Earkick</span>
<span >,&rdquo; &ldquo;</span>
<span class="term">we</span>
<span >,&rdquo; &ldquo;</span>
<span class="term">us</span>
<span >,&rdquo; or &ldquo;</span>
<span class="term">our</span>
<span >&rdquo;) welcomes you. &nbsp;We invite you to access and use our proprietary mental health monitor mobile app, which is accessible through tablets, smart phones, and other devices (the &ldquo;</span>
<span class="term">App</span>
<span >&rdquo;).</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >By clicking &ldquo;I AGREE,&rdquo; or otherwise manifesting assent to this Agreement (as defined below), when you sign up to access and use the App, you acknowledge that you have read, understood, and agree to be legally bound by these Terms of Use and our Privacy Policy, which is hereby incorporated by reference (collectively, this &ldquo;</span>
<span class="term">Agreement</span>
<span >&rdquo;). &nbsp;If you do not agree to any of these terms, then please do not use the App. &nbsp;We may change the terms and conditions of these Terms of Use from time to time with or without notice to you. By continuing to access and/or use the App after we have posted a modification on the App, you are indicating that you agree to be bound by the modified Agreement. If the modified Agreement is not acceptable to you, your only recourse is to cease using the App.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >Capitalized terms not defined in these Terms of Use shall have the meaning set forth in our Privacy Policy.</span>
</p>
<p >
<span >
</span>
</p>
<p >
  <strong>
<span >THE SECTIONS BELOW TITLED &ldquo;BINDING ARBITRATION&rdquo; AND &ldquo;CLASS ACTION WAIVER&rdquo; CONTAIN A BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER. &nbsp;THEY AFFECT YOUR LEGAL RIGHTS. &nbsp;PLEASE READ THEM.</span>
</strong>
</p>
<p >
<span >
</span>
</p>
<p >
<span >&nbsp;</span>
</p>
<ol class="c0 lst-kix_list_5-0 start" start="1">
<li class="  li-bullet-0">
<h3 >NO MEDICAL ADVICE</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
  <strong>

<span >You acknowledge and agree that Earkick does not, through the App, or otherwise, provide any form of medical care, medical opinion, medical advice, diagnosis, or treatment, and that Earkick does not evaluate the need to seek medical attention. Earkick only provides the platform through which you can monitor and track your mental health and personalized goals. The App and the Content (as defined below) are for informational purposes only, and are not intended as a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on the App and/or the Content. You acknowledge and agree that by accessing and using the App, you are not entering into a doctor-patient or provider-patient relationship with Earkick. If you think you may have a medical emergency, call your doctor or 911 immediately. Reliance on the App and the Earkick Content is solely at your own risk.</span>
  </strong>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="2">
<li class="  li-bullet-0">
<h3 >DESCRIPTION AND USE OF THE APP </h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >The App allows you to monitor your mental health, understand yourself better, build healthy habits and become more resilient against stress, and burnout. The App also provides weekly summary reports to you. If you would like to use the App, you will need to download the App from the Apple, Android or any other app store through which the App is made available to you using your app store credentials.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >Earkick is under no obligation to accept any individual as a user of the App, and may accept or reject any user in its sole and complete discretion. &nbsp;</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="3">
<li class=" li-bullet-1">
<h3 >LICENSE TO USE THE APP</h3>

</li>
</ol>

<p >
<span >Earkick hereby grants you a limited, non-exclusive, non-transferable license to download and install a copy of the mobile App on a single mobile device that you own or control and to run such copy of the App solely for your own personal use.</span>

<span class="highlighted">
  <span>Furthermore, with respect to any App accessed through or downloaded from the Apple, Inc. (&ldquo;</span>
  <span class="term">Apple</span>
  <span >&rdquo;) application store (&ldquo;</span>
  <span class="term">Apple App</span>
  <span >&rdquo;), you will use the Apple App only: (i) on an Apple-branded product that runs iOS (Apple&rsquo;s proprietary operating system software); and (ii)&nbsp;as permitted by the &ldquo;Usage Rules&rdquo; set forth in the App Store Terms of Service</span>
</span>

<span >. We reserve all rights in and to the App not expressly granted to you under these Terms of Use.</span>

</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="4">
<li class=" li-bullet-1">
<h3 >FEES</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >The App is currently provided to you free of charge.We reserve the right to institute new or additional fees, at any time upon notice to you.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="5">
<li class=" li-bullet-2">
<h3 >COMMUNITY GUIDELINES</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >Earkick&rsquo;s community, like any community, functions best when its users follow a few simple rules. &nbsp;By accessing and/or using the App, you agree to comply with these community guidelines (the &ldquo;</span>
<span >Community Guidelines</span>
<span >&rdquo;) and that: &nbsp;</span>
</p>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_6-0 start">
<li class="  li-bullet-1">
<span >You will comply with all applicable laws in your use of the App and will not use the App for any unlawful purpose;</span>
</li>
</ul>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_6-0">
  <li class="  li-bullet-2">
    <span >You will not upload, post, e-mail, transmit, or otherwise make available any content that:</span>

    <ul class="c0 lst-kix_list_5-1 start">
      <li class="  li-bullet-2">
      <span >infringes any copyright, trademark, right of publicity, or other proprietary rights of any person or entity; or</span>
      </li>
      <li class="  li-bullet-2">
      <span >is defamatory, libelous, indecent, obscene, pornographic, sexually explicit, invasive of another&rsquo;s privacy, promotes violence, or contains hate speech (i.e., speech that attacks or demeans a group based on race or ethnic origin, religion, disability, gender, age, veteran status, and/or sexual orientation/gender identity; or</span>
      </li>
      <li class="  li-bullet-1">
      <span >discloses any sensitive information about another person, including that person&rsquo;s e-mail address, postal address, phone number, credit card information, or any similar information.</span>
      </li>
    </ul>
  </li>
</ul>

<ul class="c0 lst-kix_list_7-0 start">
<li class="  li-bullet-1">
<span >You will not &ldquo;stalk,&rdquo; threaten, or otherwise harass another person;</span>
</li>
</ul>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_7-0">
<li class="  li-bullet-2">
<span >You will not access or use the App to collect any market research for a competing business;</span>
</li>
</ul>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_7-0">
<li class="  li-bullet-2">
<span >You will not impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; </span>
</li>
</ul>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_7-0">
<li class="  li-bullet-2">
<span >You will not interfere with or attempt to interrupt the proper operation of the App through the use of any virus, device, information collection or transmission mechanism, software or routine, or access or attempt to gain access to any data, files, or passwords related to the App through hacking, password or data mining, or any other means; </span>
</li>
</ul>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_7-0">
<li class="  li-bullet-1">
<span >You will not cover, obscure, block, or in any way interfere with any advertisements and/or safety features (e.g., report abuse button) on the App; &nbsp; </span>
</li>
</ul>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_7-0">
<li class="  li-bullet-1">
<span >You will not use any robot, spider, scraper, or other automated means to access the App for any purpose without our express written permission; provided, however, we grant the operators of public search engines permission to use spiders to copy materials from the App for the sole purpose of and solely to the extent necessary for creating publicly-available searchable indices of the materials, but not caches or archives of such materials; and</span>
</li>
</ul>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_7-0">
<li class="  li-bullet-2">
<span >You will not take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on our technical infrastructure.</span>
</li>
</ul>
<p >
<span >
</span>
</p>
<p >
<span >You will let us know about inappropriate content of which you become aware. &nbsp;If you find something that violates our Community Guidelines, please let us know, and we&rsquo;ll review it.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >We reserve the right, in our sole and absolute discretion, to deny you access to the App, or any portion of thereof, without notice, and to remove any content that does not adhere to these Community Guidelines.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="6">
<li class=" li-bullet-2">
<h3 >RESTRICTIONS</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >The App is only available for individuals aged 13 years or older.&nbsp; If you are not old enough to have authority to agree to our Terms in your country or territory, your parent or guardian must agree to our Terms on your behalf. Please ask your parent or guardian to read these Terms with you.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="7">
<li class=" li-bullet-1">
<h3 >USE OF PERSONAL INFORMATION</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >Your use of the App may involve the transmission to us of certain personal information.Our policies with respect to the collection and use of such personal information are governed according to our Privacy Policy
, which are hereby incorporated by reference in its entirety.</span>
<span >&nbsp;</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="8">
<li class=" li-bullet-2">
<h3 >INTELLECTUAL PROPERTY</h3>
<sup>
</sup>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >The App contains material, such as software, text, graphics, images, sound recordings, audiovisual works, tutorials, and other material provided by or on behalf of Earkick (collectively referred to as the &ldquo;</span>
<span class="term">Content</span>
<span >&rdquo;).&nbsp; The Content may be owned by us or by third parties.&nbsp; The Content is protected under both United States and foreign laws.&nbsp; Unauthorized use of the Content may violate copyright, trademark, and other laws.&nbsp; You have no rights in or to the Content, and you will not use the Content except as permitted under this Agreement.&nbsp; No other use is permitted without prior written consent from us.&nbsp; You must retain all copyright and other proprietary notices contained in the original Content on any copy you make of the Content.&nbsp; You may not sell, transfer, assign, license, sublicense, or modify the Content or reproduce, display, publicly perform, make a derivative version of, distribute, or otherwise use the Content in any way for any public or commercial purpose.&nbsp; The use or posting of the Content on any other website or in a networked computer environment for any purpose is expressly prohibited.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >If you violate any part of this Agreement, your permission to access and/or use the Content automatically terminates and you must immediately destroy any copies you have made of the Content.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >The trademarks, service marks, and logos of Earkick (&ldquo;</span>
<span  class="term">Earkick Trademarks</span>
<span >&rdquo;) used and displayed on the App are registered and unregistered trademarks or service marks of Earkick. &nbsp;Other company, product, and service names located on the App may be trademarks or service marks owned by others (the &ldquo;</span>
<span  class="term">Third-Party Trademarks</span>
<span >,&rdquo; and, collectively with Earkick Trademarks, the &ldquo;</span>
<span  class="term">Trademarks</span>
<span >&rdquo;).&nbsp; Nothing on the App should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Trademarks, without our prior written permission specific for each such use.&nbsp; Use of the Trademarks as part of a link to or from any site is prohibited unless establishment of such a link is approved in advance by us in writing.&nbsp; All goodwill generated from the use of Earkick Trademarks inures to our benefit.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >Elements of the App are protected by trade dress, trademark, unfair competition, and other state and federal laws and may not be copied or imitated in whole or in part, by any means, including, but not limited to, the use of framing or mirrors. &nbsp;None of the Content may be retransmitted without our express, written consent for each and every instance.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="9">
<li class=" li-bullet-1">
<h3 >COMMUNICATIONS WITH US</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >Although we encourage you to e-mail us, we do not want you to, and you should not, e-mail us any content that contains confidential information. &nbsp;With respect to all e-mails and communications you send to us, including, but not limited to, feedback, questions, comments, suggestions, and the like, we shall be free to use any ideas, concepts, know-how, or techniques contained in your communications for any purpose whatsoever, including but not limited to, the development, production, and marketing of products and services that incorporate such information without compensation or attribution to you.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0 highlighted" start="10">
<li class=" li-bullet-2">
<h3 class="highlighted">ACCESSING AND DOWNLOADING THE APP FROM ITUNES</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p class="highlighted">
<span >The following terms apply to any Apple App. These terms are in addition to all other terms contained in these Terms of Use:</span>
</p>
<p >
<span >
</span>
</p>
<ul class="c0 lst-kix_list_14-0 start highlighted">
<li class=" li-bullet-1">
<span >You acknowledge and agree that (i) these Terms of Use are concluded between you and Earkick only, and not Apple, and (ii) Earkick, not Apple, is solely responsible for the App and content thereof. Your use of the App must comply with the App Store Terms of Service.</span>
</li>
<li class=" li-bullet-1">
<span >You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App.</span>
</li>
<li class=" li-bullet-1">
<span >In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App to you and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App. As between Earkick and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Earkick.</span>
</li>
<li class=" li-bullet-2">
<span >You acknowledge that, as between Earkick and Apple, Apple is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</span>
</li>
<li class=" li-bullet-1">
<span >You acknowledge that, in the event of any third party claim that the App or your possession and use of that App infringes that third party&rsquo; s intellectual property rights, as between Earkick and Apple, Earkick, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms of Use.</span>
</li>
<li class=" li-bullet-1">
<span >You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &ldquo;terrorist supporting&rdquo; country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</span>
</li>
<li class=" li-bullet-1">
<span >You acknowledge and agree that Apple, and Apple&rsquo;s subsidiaries, are third party beneficiaries of these Terms of Use as related to your license of the App, and that, upon your acceptance of the terms and conditions of these Terms of Use, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use as related to your license of the App against you as a third party beneficiary thereof.</span>
</li>
<li class=" li-bullet-1">
<span >Without limiting any other terms of these Terms of Use, you must comply with all applicable third party terms of agreement when using the App.</span>
</li>
</ul>

<p >
<span >
</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="11">
<li class=" li-bullet-1">
<h3 >NO WARRANTIES; LIMITATION OF LIABILITY</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >NONE OF EARKICK, ITS AFFILIATES, SUBSIDIARIES, OR ITS OR THEIR OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS (COLLECTIVELY THE &ldquo;</span>
<span >EARKICK PARTIES</span>
<span >&rdquo;) ENDORSE ANY CONTENT PROVIDED THROUGH THE APP, AND/OR ANY OPINION, RECOMMENDATION OR ADVICE EXPRESSED THROUGH THE APP. &nbsp;NONE OF THE EARKICK PARTIES IS A PARTY TO, OR HAS ANY RESPONSIBILITY OR LIABILITY WITH RESPECT TO, OR FOR ANY RESULTS CAUSED BY USING THE APP, INCLUDING WITHOUT LIMITATION, ANY DEATH, BODILY INJURY OR HEALTH PROBLEMS YOU MAY SUFFER. FOR THE AVOIDANCE OF DOUBT, THE FOREGOING SENTENCE DOES NOT APPLY TO CONSUMERS LOCATED IN NEW JERSEY.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >THE CONTENT PROVIDED ON THE APP, AND IN ANY OTHER COMMUNICATIONS FROM OR PROVIDED THROUGH THE APP IS NOT INTENDED AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT.IF YOU THINK YOU OR YOUR FAMILY MEMBER(S) MAY HAVE A MEDICAL EMERGENCY, CALL 911 OR GO TO THE EMERGENCY ROOM IMMEDIATELY.</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >THE APP IS PROVIDED ON AN &ldquo; AS IS&rdquo; AND &ldquo; AS AVAILABLE&rdquo; BASIS, AND NONE OF THE EARKICK PARTIES MAKE ANY WARRANTIES WITH RESPECT TO THE SAME OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT, AND THE EARKICK PARTIES HEREBY DISCLAIM ANY AND ALL EXPRESS, IMPLIED, OR STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, ERROR-FREE OR UNINTERRUPTED OPERATION, AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE.&nbsp; TO THE EXTENT THAT ANY EARKICK PARTY MAY NOT AS A MATTER OF APPLICABLE LAW DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE AND DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED UNDER SUCH LAW.</span>
</p>
<p >
<span >
</span>
</p>
<p>
<span >IN CONNECTION WITH ANY WARRANTY, CONTRACT, OR COMMON LAW TORT CLAIMS: (I) WE SHALL NOT BE LIABLE FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO ACCESS AND USE THE APP, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND (II) ANY DIRECT DAMAGES THAT YOU MAY SUFFER AS A RESULT OF YOUR USE OF THE APP, SHALL BE LIMITED TO ONE HUNDRED DOLLARS ($100).</span>
</p>
<p>
<span >
</span>
</p>
<p >
<span >THE APP MAY CONTAIN TECHNICAL INACCURACIES, TYPOGRAPHICAL ERRORS, OR OMISSIONS.WE ARE NOT RESPONSIBLE FOR ANY SUCH TYPOGRAPHICAL, TECHNICAL, OR OTHER ERRORS LISTED ON OR OMITTED FROM THE APP.WE RESERVE THE RIGHT TO MAKE CHANGES, CORRECTIONS, AND/OR IMPROVEMENTS TO THE APP AT ANY TIME WITHOUT NOTICE.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="12">
<li class=" li-bullet-1">
<h3 >EXTERNAL SITES</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >The App may contain links to third-party websites (&ldquo;</span>
<span class="term">External Sites</span>
<span >&rdquo;). &nbsp;These links are provided solely as a convenience to you and not as an endorsement by us of the content on such External Sites. &nbsp;The content of such External Sites is developed and provided by others. &nbsp;You should contact the site administrator or webmaster for those External Sites if you have any concerns regarding such links or any content located on such External Sites. &nbsp;We are not responsible for the content of any linked External Sites and do not make any representations regarding the content or accuracy of materials on such External Sites. &nbsp;You should take precautions when downloading files from all websites to protect your computer from viruses and other destructive programs. &nbsp;If you decide to access linked External Sites, you do so at your own risk.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="13">
<li class=" li-bullet-1">
<h3 >INDEMNIFICATION</h3>

</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >You agree to defend, indemnify, and hold harmless the Earkick Parties from and against any and all damages, liabilities, losses, costs, and expenses, including reasonable attorney&rsquo;s fees (collectively, &ldquo;</span>
<span class="term">Losses</span>
<span >&rdquo;) incurred by any Earkick Party in connection with a third-party claim, action, or proceeding (each, a &ldquo;</span>
<span class="term">Claim</span>
<span >&rdquo;) arising from (i) your breach of this Agreement; (ii) any misuse of the Content, or the App; or (iii) your violation of any third-party right, including without limitation any copyright, trademark, property, or privacy right; </span>
<span class="term">provided</span>
<span >, </span>
<span >however</span>
<span >, that the foregoing obligations shall be subject to our: (i) promptly notifying you of the Claim; (ii) providing you, at your expense, with reasonable cooperation in the defense of the Claim; and (iii) providing you with sole control over the defense and negotiations for a settlement or compromise.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="14">
<li class=" li-bullet-1">
<h3 >COMPLIANCE WITH APPLICABLE LAWS</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >The App is based in the United States. We make no claims concerning whether the App, or the Content may be downloaded, viewed, or be appropriate for use outside of the United States. &nbsp;If you access the App, or the Content from outside of the United States, you do so at your own risk. &nbsp;Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="15">
<li class=" li-bullet-2">
<h3 >TERMINATION OF THE AGREEMENT</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >We reserve the right, in our sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the App, at any time and for any reason without prior notice or liability. &nbsp;We reserve the right to change, suspend, or discontinue all or any part of the App at any time without prior notice or liability.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="16">
<li class=" li-bullet-2">
<h3 >BINDING ARBITRATION</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >In the event of a dispute arising under or relating to this Agreement, the App or any other products or services provided by us (each, a &ldquo;</span>
<span >Dispute</span>
<span >&rdquo;), such dispute will be finally and exclusively resolved by binding arbitration governed by the Federal Arbitration Act (&ldquo;</span>
<span >FAA</span>
<span >&rdquo;). NEITHER PARTY SHALL HAVE THE RIGHT TO LITIGATE SUCH CLAIM IN COURT OR TO HAVE A JURY TRIAL, EXCEPT EITHER PARTY MAY BRING ITS CLAIM IN ITS LOCAL SMALL CLAIMS COURT, IF PERMITTED BY THAT SMALL CLAIMS COURT RULES AND IF WITHIN SUCH COURT&rsquo;S JURISDICTION. &nbsp;ARBITRATION IS DIFFERENT FROM COURT, AND DISCOVERY AND APPEAL RIGHTS MAY ALSO BE LIMITED IN ARBITRATION. &nbsp;All disputes will be resolved before a neutral arbitrator, whose decision will be final except for a limited right of appeal under the FAA. &nbsp;The arbitration shall be commenced and conducted by the Judicial Arbitration and Mediation Services (&ldquo;</span>
<span class="term">JAMS</span>
<span >&rdquo;) pursuant to its then current Comprehensive Arbitration Rules and Procedures and in accordance with the Expedited Procedures in those rules, or, where appropriate, pursuant to JAMS&rsquo; Streamlined Arbitration Rules and Procedures.All applicable JAMS&rsquo; rules and procedures are available at the JAMS website </span>
<span class="  1">
<a class="6" href="https://www.google.com/url?q=http://www.jamsadr.com/&amp;sa=D&amp;source=editors&amp;ust=1670577954174459&amp;usg=AOvVaw3CXGolWEQdxwC8gIACYXm3">http://www.jamsadr.com</a>
</span>
<span >. Each party will be responsible for paying any JAMS filing, administrative and arbitrator fees in accordance with JAMS rules. Judgment on the arbitrator&rsquo;s award may be entered in any court having jurisdiction. This clause shall not preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction. The arbitration may be conducted in person, through the submission of documents, by phone, or online. If conducted in person, the arbitration shall take place in the United States county where you reside. The parties may litigate in court to compel arbitration, to stay a proceeding pending arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator. &nbsp;The parties shall cooperate in good faith in the voluntary and informal exchange of all non-privileged documents and other information (including electronically stored information) relevant to the Dispute immediately after commencement of the arbitration. Nothing in this Agreement will prevent Earkick from seeking injunctive relief in any court of competent jurisdiction as necessary to protect Earkick&rsquo; proprietary interests.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="17">
<li class=" li-bullet-1">
<h3 >CLASS ACTION WAIVER</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >You agree that any arbitration or proceeding shall be limited to the Dispute between us and you individually. To the full extent permitted by law, (i) no arbitration or proceeding shall be joined with any other; (ii) there is no right or authority for any Dispute to be arbitrated or resolved on a class action-basis or to utilize class action procedures; and (iii) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons. YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST US ONLY IN YOUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="18">
<li class=" li-bullet-2">
<h3 >EQUITABLE RELIEF</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >You acknowledge and agree that in the event of a breach or threatened violation of our intellectual property rights and confidential and proprietary information by you, we will suffer irreparable harm and will therefore be entitled to injunctive relief to enforce this Agreement. We may, without waiving any other remedies under this Agreement, seek from any court having jurisdiction any interim, equitable, provisional, or injunctive relief that is necessary to protect our rights and property pending the outcome of the arbitration referenced above. You hereby irrevocably and unconditionally consent to the personal and subject matter jurisdiction of the federal and state courts in the State of California for purposes of any such action by us.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="19">
<li class=" li-bullet-1">
<h3 >CONTROLLING LAW; EXCLUSIVE FORUM</h3>
</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >The Agreement and any action related thereto will be governed by the laws of the State of California without regard to its conflict of laws provisions. &nbsp;The Parties hereby consent and agree to the exclusive jurisdiction of the state and federal courts located in the State of California for all suits, actions, or proceedings directly or indirectly arising out of or relating to this Agreement, and waive any and all objections to such courts, including but not limited to, objections based on improper venue or inconvenient forum, and each party hereby irrevocably submits to the exclusive jurisdiction of such courts in any suits, actions, or proceedings arising out of or relating to this Agreement.</span>
</p>
<p >
<span >
</span>
</p>
<ol class="c0 lst-kix_list_5-0" start="20">
<li class=" li-bullet-1">
<h3 >MISCELLANEOUS</h3>

</li>
</ol>
<p >
<span >
</span>
</p>
<p >
<span >If the Agreement is terminated in accordance with the termination provision in </span>
<span >Section 15</span>
<span >&nbsp;above, such termination shall not affect the validity of the following provisions of this Agreement, which shall remain in full force and effect: &nbsp;&ldquo;Intellectual Property,&rdquo; &ldquo;Communications with Us,&rdquo; &ldquo;No Warranties; Limitation of Liability,&rdquo; &ldquo;Indemnification,&rdquo; &ldquo;Compliance with Applicable Laws,&rdquo; &ldquo;Termination of the Agreement,&rdquo; &ldquo;Binding Arbitration,&rdquo; &ldquo;Class Action Waiver,&rdquo; &ldquo;Controlling Law; Exclusive Forum,&rdquo; and &ldquo;Miscellaneous.&rdquo; &nbsp;</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >Our failure to act on or enforce any provision of the Agreement shall not be construed as a waiver of that provision or any other provision in this Agreement. &nbsp;No waiver shall be effective against us unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance. &nbsp;Except as expressly agreed by us and you in writing, this Agreement constitutes the entire Agreement between you and us with respect to the subject matter, and supersedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter. &nbsp;The section headings are provided merely for convenience and shall not be given any legal import. &nbsp;This Agreement will inure to the benefit of our successors, assigns, licensees, and sublicensees. &nbsp;</span>
</p>
<p >
<span >
</span>
</p>
<p >
<span >Copyright 2022 Earkick, Inc. All rights reserved. &nbsp;</span>
</p>

  </div>
)

export default TermsOfService
