import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';
import panda from '../../../assets/pandas/greeting.png'
import '../index.scss';
import GreetPanda from '../components/greet-panda';
import OnboardingProgressBar from '../components/progress-bar';
import { useSelector } from 'react-redux';
import { lazy, useState, useEffect } from 'react';
import ScrollToTop from '../../../Components/scroll-ro-top';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));
const PreloadImages = lazy(() => import('../preload-images'));

function OnboardingName() {
  const navigate = useNavigate()
  const { appId } = useSelector(state => state.user)
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  return (
    <Container className="onboarding onboarding-name onboarding-standard-section">
      <OnboardingProgressBar 
        value={4}
        back={() => navigate("/onboarding/start")}
      />

      <Row className="onboarding-content">
        <GreetPanda
          text="Hi there! I&apos;m Panda."
          panda={<img src={panda} alt="Panda" />}
          shadow={true}
        />
      </Row>

      <Row className="cta-button justify-content-center pt-5">
        <Button color="primary" onClick={() => navigate("/onboarding/name")}>Continue</Button>
      </Row>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O02 Onboarding Hi', 
          data: {
            appId
          }}}
        />
      }

      <ScrollToTop />
      <PreloadImages />
    </Container>
  );
}

export default OnboardingName;