import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import '../index.scss';
import './index.scss';
import Variant2 from './variants/2/index.jsx';
import '../index.scss';
import './index.scss';
import { useAppSelector } from '../../../store/Hooks';
import { webappStripeCheckoutCreateSession } from '../../../api/onboarding/stripe';
import stripeConfig from '../../../config/stripe'
import { stripePlans } from './stripe';
import { memberUpdate } from '../../../api/member';
import ScrollToTop from '../../../Components/scroll-ro-top';

const plans = []
stripeConfig.plans.map(plan => {
  stripePlans.map(id => {
    if(plan.id === id) {
      plans.push(plan)
    }
  })
})

let domain = `https://${window.location.hostname}`

if(window.location.hostname === 'localhost') {
  domain = 'http://localhost:3000'
}

function OnboardingPayment() {
  const [planSelected, setPlanSelected] = useState(plans[0].id)
  const [stripeSession, setStripeSession] = useState(false)
  const { appId } = useAppSelector((state) => state.user);

  useEffect(() => {
    if(appId && !stripeSession) {
      webappStripeCheckoutCreateSession(
        appId,
        planSelected,
        _.find(plans, { id: planSelected }).trialDays,
        `${domain}/onboarding/success`,
        `${domain}/onboarding/payment`,
      )
      .then(session => {
        console.log(session)
        setStripeSession(session)
        memberUpdate(appId, { stripe_session_id: session.sessionObject.id })
      })
    }
  }, [appId, planSelected, stripeSession])

  const startStripe = async () => {
    if(stripeSession.session_url) {
      window.location.href = stripeSession.session_url
    }
  }

  return (
    <>
      <ScrollToTop />
      <Variant2
        plans={plans}
        setPlanSelected={setPlanSelected}
        setStripeSession={setStripeSession}
        planSelected={planSelected}
        startStripe={startStripe}
        stripeSession={stripeSession}
      />
    </>
  );
}

export default OnboardingPayment;