import React from "react";
import { Outlet } from 'react-router'
import './index.scss'
import { Container, Row } from "reactstrap";
import config from '../../../config'
import { useSelector } from "react-redux";
const dev = config.environment === 'DEVELOPMENT';

export default function GuestLayout() {
  const { appId } = useSelector((state) => state.user)
  return (
    <Container fluid className="onboarding-layout">
      <Row className="outlet">
        <Outlet />
          <Row className="centered-text">
            <>
              {appId} <br /> {dev ? 'dev' : '© 2025'}
            </>
          </Row>
      </Row>
    </Container>
  );
}