import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

const webappOnboardingSave = async (appId, update) => {
  const call = httpsCallable(functions, 'webappOnboardingSave')

  const { data } = await call({
    appId, 
    update
  })

  return data
}

export {
  webappOnboardingSave,
}
