import { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Input, Row } from 'reactstrap';
import { setName } from '../store';
import Question from '../components/question';
import '../index.scss';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import OnboardingProgressBar from '../components/progress-bar';
import { memberUpdate } from '../../../api/member';
import { useAppSelector } from '../../../store/Hooks';
import ScrollToTop from '../../../Components/scroll-ro-top';
import PreloadImages from '../preload-images';
import { webappOnboardingSave } from '../../../api/onboarding';
const Mixpanel = lazy(() => import("../../../api/mixpanel.jsx"));

function OnboardingName() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onboarding = useSelector(state => state.onboarding)
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  return (
    <>
      <ScrollToTop />
      <Container className="onboarding onboarding-name onboarding-standard-section">
        <OnboardingProgressBar 
          value={160/20}
          back={() => navigate("/onboarding/greetings")}
          next={() => navigate("/onboarding/meet")}
        />

        <div className="onboarding-content">
          <Question 
            question="How should I call you?"
          />
          
          <div className="justify-content-center pt-5">
            <Input
              type="text" 
              defaultValue={onboarding.name}
              autoFocus
              onBlur={(event) => { 
                const name = event.target.value.trim()
                localStorage.setItem('onboarding.name', name)
                dispatch(setName(name)) 
                memberUpdate(appId, { onboarding: { ...onboarding, name } })
                webappOnboardingSave(appId, { name })
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  const name = event.target.value.trim()
                  localStorage.setItem('onboarding.name', name)
                  dispatch(setName(name)) 
                  memberUpdate(appId, { onboarding: { ...onboarding, name } })
                  navigate("/onboarding/meet")
                }
              }}
              />
          </div>

          <Row className="cta-button justify-content-center pt-5">
            <Button color="primary" onClick={() => navigate("/onboarding/meet")}>Continue</Button>
          </Row>
        </div>

        {triggerMixpanel &&
          <Mixpanel
          success={() => setTriggerMixpanel(false)}
          track={{ 
            event: 'O03 Onboarding User name', 
            data: {
              appId
            }}}
          />
        }
        <PreloadImages />
      </Container>
    </>
  );
}

export default OnboardingName;