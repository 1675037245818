import _ from 'lodash'
import { lazy, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { Button, Row } from 'reactstrap';

import heroImage from './assets/hero.png?react';
import plansImage from './assets/plans.png?react';

import './index.scss'
import { useAppSelector } from '../../../../../store/Hooks';

const Mixpanel = lazy(() => import("../../../../../api/mixpanel.jsx"));


const PaymentFirst = () => {
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);
  const navigate = useNavigate();

  return (
    <div className="onboarding onboarding-payment payment variant2 first-page">
      <Row className="header">
        <h1>Premium members are likely to reach their goals!</h1>
      </Row>

      <Row className="justify-content-center hero">
        <img src={heroImage} alt="featured" />
      </Row>

      <div className="clouds"></div>

      <Row className="justify-content-center plans">
        <img src={plansImage} alt="plans" />
      </Row>

      <div className="cta-section">
        <div class="cta-section-inner">
          <Button
            className="purchase-button" 
            onClick={() => {
              navigate("/onboarding/payment/second")
            }}
            >Start My Free Week</Button>
          <Button color="secondary" onClick={() => navigate("/onboarding/finish")}>No thanks</Button>
        </div>
      </div>

      {triggerMixpanel &&
        <Mixpanel
        success={() => setTriggerMixpanel(false)}
        track={{ 
          event: 'O20 Onboarding Payment',
          data: {
            appId,
            variant: 2
          }}}
        />
      }
    </div>
  );
};

export default PaymentFirst;