import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Progress } from 'reactstrap';
import '../index.scss';
import './index.scss';
//import startChat from '../../dashboard/start-chat';
import OnboardingProgressBar from '../components/progress-bar';
import config from '../../../config/index'
import { useSelector } from 'react-redux';
import Mixpanel from "../../../api/mixpanel.jsx";
import heroImage from './assets/hero.png'
import ScrollToTop from '../../../Components/scroll-ro-top';
const { environment, domain, adjustCode } = config

const getLink = (appId) => {
  const fallback = encodeURIComponent(`${domain.replace('chat.', '')}/web-onboarding?adj_t=${adjustCode}&onboardingId=${appId}`);
  const adjustDynamicLinkBase = environment === 'production' ? 
    `https://earkick.go.link/web-onboarding?adj_t=${adjustCode}&adj_fallback=${fallback}`: 
    `https://earkickdev.go.link/web-onboarding?adj_t=${adjustCode}&adj_fallback=${fallback}`;
  console.log(`Adjust link: ${adjustDynamicLinkBase}`)
  return adjustDynamicLinkBase
}

function OnboardingSecurity() {
  const navigate = useNavigate()
  const [progress, setProgress] = useState(0)
  const { appId } = useSelector(state => state.user)
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);

  useEffect(() => {
    const time = Math.random();
    const interval = setInterval(() => {
      let newProgress = progress + Math.floor(Math.random() * 2) + 1
      if(newProgress > 100) {
        newProgress = 100
      }

      setProgress(newProgress)
    }, 100 * time)

    if(progress == 100) {
      clearInterval(interval)
      //startChat({ metadata: { persona_id: 'dr_panda' } }, url => navigate(url))
      navigate('/onboarding/payment')
      
      /*
      const key = 'onboarding.appId'
      // remove from local storage
      
      const link = [
        getLink(appId),
        `adj_redirect_macos=https%3A%2F%2F${domain.replace('chat.', '').replace('https://', '')}%2Fweb-onboarding`,
        `onboardingId=${appId || localStorage.getItem(key)}`
      ]
  
      window.location = link.join('&')
      */
    }

    return () => clearInterval(interval)
  }, [progress])

  
  return (
    <>
      <ScrollToTop />
      <Container className="onboarding app-security onboarding-standard-section">
        <OnboardingProgressBar 
          value={95}
          back={() => navigate("/onboarding/achievements")}
        />
        <div className="app-personalizin-frame align-start">
          <img src={heroImage} alt="Security" className="hero-image" />
        </div>

        <div className="onboarding-content">
          <div className="pt-5 description">
            <h3>Your data is 100% private and no one can see it.</h3>
          </div>
                
          <div className="progress-meter justify-content-center pt-4">
            <Progress value={progress} max="100" />
            <p>Personalizing your experience...</p>
            <div className="progress-number">{progress}%</div>
          </div>
        </div>

        {triggerMixpanel &&
          <Mixpanel
            track={{
              event: 'O99 Onboarding Personalization screen', 
              data: {
                appId
              }}}
            success={() => setTriggerMixpanel(false)}
          />
        }
      </Container>
    </>
  );
}

export default OnboardingSecurity;