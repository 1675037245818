import React from 'react';
import stars from '../../../assets/⭐️⭐️⭐️⭐️⭐️.png'

const OnboardingStoriesReview = ({ who, title, description }) => {
  return (
    <div className="review">
      <img src={stars} alt="stars" />
      <p className="who">{who}</p>
      <p className="title"><strong>{title}</strong></p>
      <p>{description}</p>
    </div>
  );
}

export default OnboardingStoriesReview;