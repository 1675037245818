import { useEffect, useState } from 'react';
const { getMixpanel } = require('./mixpanel')

// This hack is to make Mixpanel lazy loaded
export default function Mixpanel({ identify, track, success }) {
  const [triggerMixpanel, setTriggerMixpanel] = useState(true);
  const mixpanel = getMixpanel()

  useEffect(() => {
    if(triggerMixpanel) {
      if (identify) {
        mixpanel.identify(identify)
      }
      
      if (track) {
        mixpanel.track(track.event, track.data)
      }
      
      if (success) {
        success()
        setTriggerMixpanel(false)
      }
    }

  }, [triggerMixpanel, identify, track])

  return null
}